.container {
  position: fixed;
  z-index: 2147483600;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  padding: 10px;
  padding-bottom: 70px;
  display: flex;
}