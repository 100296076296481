.container {
  font-family: GalanoGrotesqueAlt, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-weight: 700;
  letter-spacing: -0.3px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  box-sizing: border-box;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  height: 45px;
  max-width: 100%;
  padding: 0 25px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background-color: #a04193;
}
