.footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 0;
  display: flex;
  align-items: flex-end;

  svg {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
  }

  span {
    color: #eceef2;
    margin-left: 15px;
    font-size: 16px;
    line-height: 13px;
    font-weight: 700;
  }

  @media (min-width: 600px) {
    svg {
      width: 30px;
      height: 30px;
    }

    span {
      margin-left: 20px;
      font-size: 18px;
      line-height: 15px;
    }
  }
}