@font-face {
  font-family: 'GalanoGrotesqueAlt';
  src: url('https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/GalanoGrotesqueAlt-Regular.woff2')
      format('woff2'),
    url('https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/GalanoGrotesqueAlt-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'GalanoGrotesqueAlt';
  src: url('https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/GalanoGrotesqueAlt-SemiBold.woff2')
      format('woff2'),
    url('https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/GalanoGrotesqueAlt-SemiBold.woff')
      format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'GalanoGrotesqueAlt';
  src: url('https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/GalanoGrotesqueAlt-Bold.woff2')
      format('woff2'),
    url('https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/GalanoGrotesqueAlt-Bold.woff')
      format('woff');
  font-weight: 900;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'GalanoGrotesqueAlt', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(59,70,90);
  background: linear-gradient(#576277, #3B465A);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
