.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  animation: 2s linear infinite svg-animation;
  width: 32px;
  max-width: 32px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: white;
  stroke-linecap: round;
  stroke-dasharray: 280;
  stroke-dashoffset: 270;
  stroke-width: 12px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  33% {
    stroke-dashoffset: 270;
    transform: rotate(0);
  }
  66% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 270;
    transform: rotate(360deg);
  }
}
