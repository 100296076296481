.container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  background: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  overflow: auto;
}
