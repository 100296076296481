.modal {
  overflow: hidden;
  background-color: white;
  position: relative;
  z-index: 2147483601;
  margin: auto;
  left: 0;
  right: 0;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  max-width: 550px;
  min-height: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-text-size-adjust: 100%;
  padding: 20px;
  gap: 20px;
  box-shadow: 0 3px 45px rgba(0, 0, 0, 0.4);

  @media (min-width: 600px) {
    padding: 40px;
  }
}
